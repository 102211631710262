@import "../../assets/styles/vars.scss";

.gallery {
  .empty-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 50px;
    box-shadow: $box-shadow;
    background-color: $white;
    height: 20vh;
    h2 {
      color: $copper;
      padding: 20px;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100px;
      object-fit: contain;
      @include mobile {
        width: 80px;
      }
    }
    .header {
      font-size: 1.2rem;
      @include mobile {
        font-size: 1rem;
      }
    }
    .cta {
      margin: 0 10px;
      padding: 10px;
      background-color: $transparent-white;
      @include mobile {
        padding: 5px;
        span {
          font-size: 12px;
        }
      }
    }
  }
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 3px;

    @include mobile {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

      * {
        font-size: 10px;
      }
    }

    .video-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s;
      background-color: white;
      cursor: pointer;

      video {
        width: 100%;
        height: 400px;
        object-fit: cover;
        @include mobile {
          height: 250px;
        }
      }

      h3 {
        margin: 10px 0;
        font-size: 1.2em;
        color: $copper;
      }

      transition: transform 0.5s, box-shadow 0.5s;
      &:hover {
        z-index: 99;
        transform: scale(1.1);
        box-shadow: 0px 8px 10px $copper;
        transition: transform 0.5s, box-shadow 0.5s;
      }
    }

    .fullscreen-video {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.5s;
      z-index: 1000;

      &.closing {
        animation: fadeOut 0.5s forwards;
      }

      .video-container {
        position: relative;
        width: 80%;
        height: 80%;
        background-color: #000;
        border-radius: 10px;
        overflow: hidden;

        video {
          width: 100%;
          height: 100%;
        }

        .close-button {
          z-index: 99;
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: rgba(255, 255, 255, 0.8);
          border: none;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          font-size: 24px;
          color: #000;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 5px;
        }
      }
    }

    @keyframes fadeIn {
      from {
        transform: scale(0.5);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      from {
        transform: scale(1);
        opacity: 1;
      }
      to {
        transform: scale(0.5);
        opacity: 0;
      }
    }
  }
}
