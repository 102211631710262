@import "../../assets/styles/vars.scss";

.about {
  scroll-snap-align: center;
  min-height: 100vh;
  gap: 40px;
  padding: 20px;

  .title{
    font-weight: bold;
  }
  @include desktop {
    flex-direction: column !important;
  }
  .services {
    gap: 20px;
    margin: 20px;
    align-items: flex-start;
  }
  .carousal-preview {
    .slider {
      width: 50vw;
      @include desktop {
        width: 100%;
      }
      img {
        height: 80vh;
        object-fit: cover;
        object-position: top;
        @include tablet {
          max-height: 60vh;
        }
        @include mobile {
          max-height: 40vh;
        }
      }
    }
  }
}
