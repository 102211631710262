@import "../../assets/styles/vars.scss";

.footer {
  scroll-snap-align: center;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 50px;

  @include mobile {
    margin: 0;
  }

  .profile-card {
    border-radius: 8px;
    position: relative;
    padding: 20px;
    background-color: $transparent-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 50px;
    box-shadow: $box-shadow;

    @include tablet {
      flex-direction: column;
      gap: 20px;
    }

    .carousel-root {
      width: 300px;
      margin: 0 auto;

      @include mobile {
        width: 150px;
        height: 150px;
      }

      .carousel .slider-wrapper {
        overflow: hidden;
        border-radius: 50%; // Make the wrapper circular

        box-shadow: unset; // Optional: Add shadow for a more defined circular look
      }

      .carousel .slide img {
        border-radius: 50%; // Make the images circular
        width: 300px;
        height: 300px;
        object-fit: contain;
        background-color: $raisin-black;
        @include tablet {
          width: 200px;
          height: 200px;
        }

        @include mobile {
          width: 150px;
          height: 150px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1,
      h2 {
        margin-bottom: 20px;

        @include tablet {
          font-size: 15px;
        }
      }

      .bio {
        margin-bottom: 20px;

        @include tablet {
          font-size: 13px;
        }
      }

      .instagram-info {
        margin-bottom: 20px;
        display: flex;
        gap: 30px;
        width: 100%;
        justify-content: center;
        align-items: center;

        @include desktop {
          flex-direction: column;
          gap: 10px;
          margin-bottom: 5px;

          div {
            width: 100%;
          }
        }

        .border {
          color: $raisin-black;
          flex: 1;
          border-radius: 10px;
          padding: 5px;
          border-left: 8px solid #b9895d;
          background-color: rgb(255, 253, 249);

          .number {
            font-weight: bold;

            @include tablet {
              font-size: 14px;
            }
          }

          span {
            font-size: 1.2rem;

            @include tablet {
              font-size: 14px;
            }
          }
        }
      }

      .label {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 10px;

        @include tablet {
          font-size: 14px;
        }

        .icon {
          background-color: $copper;
          color: $white;
          padding: 5px;
          border-radius: 50%;
          margin: 0 2px;
          width: 12px;
          height: 12px;
          box-sizing: content-box;

          @include tablet {
            width: 10px;
            height: 10px;
          }
        }
      }

      .social-links {
        margin-top: 20px;
        display: flex;
        gap: 15px;

        @include tablet {
          margin-top: 10px;
          gap: 10px;
        }

        .icon {
          color: $white;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          padding: 10px;
          box-sizing: content-box;
          transition: 0.2s ease;

          @include tablet {
            width: 15px;
            height: 15px;
          }

          &:hover {
            cursor: pointer;
            box-shadow: 0 0 0 5px $white;
          }
        }

        a:nth-child(1) .icon {
          background-color: #1877f2; // Facebook
        }

        a:nth-child(2) .icon {
          background-color: #e4405f; // Instagram
        }

        a:nth-child(3) .icon {
          background-color: #010101; // TikTok
        }

        a:nth-child(4) .icon {
          background-color: #d44638; // Mail
        }
      }
    }
  }
}
