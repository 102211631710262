@import "../../assets/styles/vars.scss";

.gallery-card {
  scroll-snap-align: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 50px;

  @include mobile {
    margin: 0;
  }

  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $transparent-white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    @include tablet {
      flex-direction: column;
    }
    .cta {
      padding: 10px;
      align-self: flex-end;
    }

    img {
      width: 100%;
      height: 60vh;
      border-radius: 8px;
      object-fit: cover;
      @include screen {
        width: 50%;
        height: 40vh;
      }
      @include tablet {
        width: 100%;
        height: 40vh;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 10px;
      h2 {
        margin-top: 10px;
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

      p {
        text-align: center;
        padding: 0 10px;
        font-size: 1.2rem;
        margin-bottom: 20px;
      }
      .cta {
        align-self: center;
      }
    }
  }
}
