@import "../../assets/styles/vars.scss";
.cta {
  position: relative;
  padding: 20px;
  color: $raisin-black;
  background-color: $white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: $raisin-black;
    width: 0%;
    height: 100%;
    transition: all 0.3s ease;
  }
  & span {
    position: relative;
    font-size: 16px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    white-space: nowrap; /* Ensure text does not wrap */
  }
  &:hover:before {
    width: 100%;
    background: $raisin-black;
  }
  &:hover {
    color: white;
  }
}
