@import "../../../assets/styles/vars.scss";

.step-form {
  background-color: inherit;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  .actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: $raisin-black;
  }

  input,
  select,
  textarea {
    resize: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid $pale-pink;
    border-radius: 4px;
    font-size: 1rem;
    color: $raisin-black;
    max-height: 20vh;

    &:focus {
      outline: none;
      border-color: $copper;
    }
  }

  button {
    position: relative;
    padding: 10px;
    color: $copper;
    border: unset;
    background: none;
    overflow: hidden;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-color: $raisin-black;
      width: 0%;
      height: 100%;
      transition: all 0.1s ease;
      z-index: -1;
    }

    letter-spacing: 0.25em;
    text-transform: uppercase;

    &:hover:before {
      width: 100%;
      background: $raisin-black;
    }

    &:hover {
      color: $white;
      cursor: pointer;
    }
  }
  .images {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-self: center;
    justify-content: center;
    align-items: center;
    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 10%;
    }
  }
  strong {
    margin: 5px;
  }

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2px;
  }
  @include mobile {
    font-size: 15px;
  }
  @include mini-mobile {
    font-size: 13px;
  }
}
