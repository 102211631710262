@import "../../assets/styles/vars.scss";

.request-form {
  scroll-snap-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: 0 50px;

  @include mobile {
    margin: 0;
  }
  .request-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
    padding: 20px;
    background-color: $transparent-white;
    text-align: center;
    transition: height 0.5s ease;
    border-radius: 8px;
    box-shadow: $box-shadow;
  }
  .step-wizard {
    align-content: center;
    overflow: hidden;
  }
  #RFS-StepContainer {
    padding: 0;
  }
  #RFS-StepperContainer {
    @include tablet {
      padding: 0;
      #RFS-Connector {
        width: 100%;
        align-self: center;
        justify-self: center;
        text-align: center;
      }
      #RFS-StepButton {
        span {
          font-size: 15px;
          margin-bottom: 3px;
          font-weight: bold;
        }
      }
      #RFS-LabelContainer {
        font-size: 12px;
      }
    }
    @include mini-mobile {
      #RFS-Connector {
        display: none;
      }

      #RFS-LabelContainer {
        font-size: 10px;
      }
    }
  }
  #RFS-StepButton {
    width: 30px;
    height: 30px;
  }

  .StepButton-0-2-4.active {
    background-color: $copper;
  }

  .StepButton-0-2-4.completed {
    background-color: $raisin-black;
  }
}
