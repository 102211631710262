@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import "./vars";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.en {
  * {
    font-family: Raleway, serif;
  }
  direction: ltr !important;
}

.ar {
  * {
    font-optical-sizing: auto;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-optical-sizing: auto;
    letter-spacing: unset !important;
  }
  direction: rtl !important;
}

html {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: $pale-pink;
  color: $copper;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

::selection {
  color: #eee;
  background: #86898d;
}

a {
  text-decoration: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.carousel-root {
  direction: ltr;
}

.ar {
  #RFS-ConnectorContainer {
    left: calc(50% + 2em - 8px);
    right: calc(-50% + 2em - 8px);
  }

  // @include tablet {
  //   .menu {
  //     top: -100%;
  //     transition: 300ms top cubic-bezier(0.77, 0, 0.175, 1);
  //     &.active {
  //       top: 0;
  //     }
  //   }
  // }
}
@import "./utils/breakpoints.scss";
