@import "../../assets/styles/vars.scss";

.navbar {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .items {
    display: flex;
    gap: 10px;
  }
  img {
    width: 100px;
  }
  .close {
    display: none;
  }
}

.menu__toggler {
  margin: 0 10px;
  height: 28px;
  width: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span,
  span::before,
  span::after {
    position: absolute;
    content: "";
    width: 28px;
    height: 2.5px;
    background: $copper;
    border-radius: 20px;
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  }

  span::before {
    top: -8px;
  }

  span::after {
    top: 8px;
  }

  &.active > span {
    background: transparent;
  }

  &.active > span::before,
  &.active > span::after {
    background: $copper;
    top: 0px;
  }

  &.active > span::before {
    transform: rotate(-225deg);
  }

  &.active > span::after {
    transform: rotate(225deg);
  }
}

.menu {
  position: absolute;
  left: -100%;
  top: 0;
  z-index: 99;
  background-color: $pale-pink;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  transition: 300ms left cubic-bezier(0.77, 0, 0.175, 1);

  @include tablet {
    clip-path: unset;
    width: 100%;
  }
  &.active {
    left: 0;
  }
  .elements {
    display: flex;
    justify-self: center;
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    width: 100%;
    padding: 10px;
    font-size: 1.4rem;
    color: $copper;
    transition: 0.3s ease;
    &:hover {
      cursor: pointer;
      transition: 0.3s ease;
      transform: translateX(10px);
    }
  }
  .logo {
    align-items: start;
  }
}

//Arabic
.ar {
  .menu {
    position: absolute;
    right: -100%;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
    transition: 300ms right cubic-bezier(0.77, 0, 0.175, 1);
    &.active {
      right: 0;
    }
    a {
      &:hover {
        transform: translateX(-10px);
      }
    }
  }
}

@include tablet {
  .ar,
  .en {
    .menu {
      right: 0;
      top: -100vh;
      transition: 300ms top cubic-bezier(0.77, 0, 0.175, 1);
      clip-path: none;
      &.active {
        top: 0;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .close {
          margin-right: 20px;
          cursor: pointer;
          text-align: right;
          display: block;
          &:hover,
          &:visited,
          &:focus {
            transform: unset;
            color: $raisin-black;
          }
        }
      }
    }
  }
}
