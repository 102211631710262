@import "../../assets/styles/vars.scss";
.startup {
  scroll-snap-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ),
    url("../../assets/images/cover.jpg");
  // background-image: url("../../assets/images/2.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  @include desktop {
    column-gap: 12%;
  }
  @include tablet {
    flex-direction: column-reverse;
    row-gap: 20px;
  }
  .image {
    img {
      border: 1px solid papayawhip;
      width: 50vh;
      height: 50vh;
      object-fit: cover;
      border-radius: 50%;
      @include desktop {
        width: 35vh;
        height: 35vh;
      }
      @include tablet {
        margin-top: 50px;
        width: 30vh;
        height: 30vh;
      }
    }
  }
  .banner {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      text-align: center;
      font-size: 30px;
      @include mobile {
        font-size: 30px;
      }
    }
    .flex-row {
      justify-content: center;
      gap: 10%;
      @include tablet {
        flex-direction: column;
        row-gap: 20px;
      }
    }
    .flex-column {
      text-align: center;
      gap: 5px;
    }
  }
}
